<template>
    <div>
        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label required">{{
                    $t("jobDetail.jobTitleLevel")
                }}</label>
                <select
                    v-model.number="model.position_level_id"
                    class="form-select"
                    :class="{
                        'is-invalid': errors.has('position_level_id')
                    }"
                    ref="position_level_id"
                >
                    <option
                        :value="null"
                        disabled
                        selected
                        style="display: none;"
                        >{{ $t("select") }}</option
                    >
                    <option
                        v-for="(positionLevel, index) in positionLevels"
                        :key="index"
                        :value="positionLevel.position_level_id"
                        >{{ positionLevel.position_level_name_en }} | {{ positionLevel.position_level_name_kh }}</option
                    >
                </select>
                <div
                    class="invalid-feedback"
                    v-if="errors.has('position_level_id')"
                >
                    {{ errors.first("position_level_id") }}
                </div>
            </div>
        </div>
        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label required">{{
                    $t("jobDetail.jobTitle")
                }}</label>
                <select
                    v-model.number="model.job_title_id"
                    class="form-select"
                    :class="{
                        'is-invalid': errors.has('job_title_id')
                    }"
                    ref="job_title_id"
                >
                    <option
                        :value="null"
                        disabled
                        selected
                        style="display: none;"
                        >{{ $t("select") }}</option
                    >
                    <option
                        v-for="(jobTitle, index) in jobTitles"
                        :key="index"
                        :value="jobTitle.job_title_id"
                        >{{ jobTitle.job_title_en }} | {{ jobTitle.job_title_kh }}</option
                    >
                </select>
                <div class="invalid-feedback" v-if="errors.has('job_title_id')">
                    {{ errors.first("job_title_id") }}
                </div>
            </div>
        </div>
        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label ">{{
                    $t("jobDetail.reportTo")
                }}</label>
                <select
                    v-model.number="model.report_to_position_id"
                    class="form-select"
                    :class="{
                        'is-invalid': errors.has('report_to_position_id')
                    }"
                    ref="report_to_position_id"
                >
                    <option
                        :value="null"
                        disabled
                        selected
                        style="display: none;"
                        >{{ $t("select") }}</option
                    >
                    <option
                        v-for="(reportToPosition,
                        index) in reportToPositionsFilter"
                        :key="index"
                        :value="reportToPosition.position_id"
                        >{{ reportToPosition.position_name_en }} {{ reportToPosition.position_level ? '=> ' + reportToPosition.position_level.position_level_name_en : '' }} {{ reportToPosition.org_chart_structure ? '=> ' + reportToPosition.org_chart_structure.org_chart_structure : '' }}</option
                    >
                </select>
                <div
                    class="invalid-feedback"
                    v-if="errors.has('report_to_position_id')"
                >
                    {{ errors.first("report_to_position_id") }}
                </div>
            </div>
        </div>
        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label required">{{
                    $t("jobDetail.jobDetailKh")
                }}</label>
                <input
                    v-model.trim="model.position_name_kh"
                    type="text"
                    class="form-control"
                    :class="{
                        'is-invalid': errors.has('position_name_kh')
                    }"
                    ref="position_name_kh"
                />
                <div
                    class="invalid-feedback"
                    v-if="errors.has('position_name_kh')"
                >
                    {{ errors.first("position_name_kh") }}
                </div>
            </div>
        </div>
        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label required">{{
                    $t("jobDetail.jobDetailEn")
                }}</label>
                <input
                    v-model.trim="model.position_name_en"
                    type="text"
                    class="form-control"
                    :class="{
                        'is-invalid': errors.has('position_name_en')
                    }"
                />
                <div
                    class="invalid-feedback"
                    v-if="errors.has('position_name_en')"
                >
                    {{ errors.first("position_name_en") }}
                </div>
            </div>
        </div>
        <div class="mb-3">
            <div class="col-md-12">
                <label class="form-col-label control-label required">{{
                    $t("jobDetail.orgChartStructure")
                }}</label>
                <select
                    v-model.number="model.org_chart_structure_id"
                    class="form-select"
                    :class="{
                        'is-invalid': errors.has('org_chart_structure_id')
                    }"
                    ref="org_chart_structure_id"
                >
                    <option
                        :value="null"
                        disabled
                        selected
                        style="display: none;"
                        >{{ $t("select") }}</option
                    >
                    <option
                        v-for="(orgChartStructure, index) in orgChartStructures"
                        :key="index"
                        :value="orgChartStructure.org_chart_structure_id"
                        >
                            {{ orgChartStructure.org_chart_structure }} {{ orgChartStructure.parent_structure ? '=> ' + orgChartStructure.parent_structure.org_chart_structure : '' }}
                            {{ orgChartStructure.level ? '| ' + orgChartStructure.level.level_description : '' }} {{ orgChartStructure.parent_level ? '=> ' + orgChartStructure.parent_level.level_description : '' }}
                        </option
                    >
                </select>
                <div
                    class="invalid-feedback"
                    v-if="errors.has('org_chart_structure_id')"
                >
                    {{ errors.first("org_chart_structure_id") }}
                </div>
            </div>
        </div>
        <div class="mt-3">
            <ts-checkbox v-model="model.is_head">{{
                $t("jobDetail.isHead")
            }}</ts-checkbox>
        </div>
        <div class="mt-3">
            <ts-checkbox v-model="model.is_allow_ot_claim">{{
                $t("jobDetail.isAllowOtClaim")
            }}</ts-checkbox>
        </div>
        <div slot="footer">
            <div class="tw-flex tw-justify-end tw-space-x-2 tw-sh">
                <ts-button
                    @click.prevent="() => $emit('cancel')"
                    class="btn-gray"
                >
                    {{ $t("cancel") }}</ts-button
                >
                <ts-button
                    v-if="!isUpdate"
                    color="primary"
                    outline
                    :waiting="waiting_new"
                    :disabled="waiting_new || waiting"
                    @click.prevent="onSaveAddNew"
                >
                    {{ $t("saveAddNew") }}</ts-button
                >
                <ts-button
                    color="primary"
                    v-if="!isUpdate"
                    :waiting="waiting"
                    :disabled="waiting_new || waiting"
                    @click.prevent="onSave"
                >
                    {{ $t("save") }}</ts-button
                >
                <ts-button
                    v-if="isUpdate"
                    color="primary"
                    @click.prevent="onUpdate"
                    :waiting="waiting"
                >
                    {{ $t("update") }}</ts-button
                >
            </div>
        </div>
        <Spin size="large" fix v-if="loading"> </Spin>
    </div>
</template>

<script>
import { Errors } from "form-backend-validation";
import { mapState, mapActions } from "vuex";
import { isEmpty, includes } from "lodash";

export default {
    name: "job-detailForm",
    data() {
        return {
            errors: new Errors(),
            loading: false,
            waiting: false,
            waiting_new: false,
            model: {
                position_name_kh: null,
                position_name_en: null,
                position_level_id: null,
                job_title_id: null,
                report_to_position_id: null,
                org_chart_structure_id: null,
                is_head: false,
                is_allow_ot_claim: false
            }
        };
    },
    computed: {
        ...mapState("humanResource/jobDetail", [
            "edit_data",
            "positionLevels",
            "jobTitles",
            "reportToPositions",
            "orgChartStructures"
        ]),
        isUpdate() {
            return !isEmpty(this.edit_data);
        },
        reportToPositionsFilter() {
            return this.reportToPositions.filter(
                i => !includes([this.edit_data.position_id], i.position_id)
            );
        }
    },
    methods: {
        ...mapActions("humanResource/jobDetail", [
            "getPositionLevel",
            "getJobTitle",
            "getReportToPosition",
            "getOrgChartStructure"
        ]),
        async fetchResource() {
            this.loading = true;
            await this.getPositionLevel();
            await this.getJobTitle();
            await this.getReportToPosition();
            await this.getOrgChartStructure();
            this.loading = false;
        },
        async onSaveAddNew() {
            this.errors = new Errors();
            this.waiting_new = true;
            this.$store
                .dispatch("humanResource/jobDetail/store", this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.$emit("fetchData");
                    this.clearInput();
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting_new = false;
                });
        },
        async onSave() {
            this.errors = new Errors();
            this.waiting = true;
            this.$store
                .dispatch("humanResource/jobDetail/store", this.model)
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.$emit("cancel");
                    this.$emit("fetchData");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        onUpdate() {
            this.waiting = true;
            this.$store
                .dispatch("humanResource/jobDetail/update", {
                    id: this.edit_data.position_id,
                    data: this.model
                })
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? "success" : "warning",
                        text: response.message
                    });
                    this.$emit("cancel");
                    this.$emit("fetchData");
                })
                .catch(error => {
                    this.errors = new Errors(error.errors);
                    this.notice({ type: "error", text: error.message });
                })
                .finally(() => {
                    this.waiting = false;
                });
        },
        setEditData() {
            this.$nextTick(function() {
                this.$refs.position_name_kh.focus();
            });
            this.fetchResource();
            if (!isEmpty(this.edit_data)) {
                this.model.position_name_kh = this.edit_data.position_name_kh;
                this.model.position_name_en = this.edit_data.position_name_en;
                this.model.position_level_id = this.edit_data.position_level_id;
                this.model.job_title_id = this.edit_data.job_title_id;
                this.model.report_to_position_id = this.edit_data.report_to_position_id;
                this.model.org_chart_structure_id = this.edit_data.org_chart_structure_id;
                this.model.is_head = this.edit_data.is_head;
                this.model.is_allow_ot_claim = this.edit_data.is_allow_ot_claim;
            }
        },
        clearInput() {
            this.errors = new Errors();
            this.model.position_name_kh = null;
            this.model.position_name_en = null;
            this.model.position_level_id = null;
            this.model.job_title_id = null;
            this.model.report_to_position_id = null;
            this.model.org_chart_structure_id = null;
            this.model.is_head = false;
            this.model.is_allow_ot_claim = false;
        },
        notice(not) {
            this.$Notice[not.type]({
                title: "JOB DETAIL",
                desc: not.text
            });
        }
    }
};
</script>
